@import "~react-image-gallery/styles/css/image-gallery.css";

.image-gallery {
    width: 40%;
    height: auto;
}

.image-gallery-slide img {
    width: 100%;
    height: auto;
    max-height: 80vh;
    object-fit: cover;
    overflow: hidden;
    object-position: center center;
}

.fullscreen .image-gallery-slide img {
    max-height: 100vh;
}
